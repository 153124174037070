import React, {useEffect, useState, useContext} from "react";

import { ch } from "@renta-apps/athenaeum-react-common";
import {Box, Button, ButtonType, Icon, Text} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import {getDeviceDocumentsAsync} from "@/services/FleetService";
import DeviceDetailsContext from "@/pages/DeviceDetailsPage/DeviceDetailsContext";
import {FileModel} from "@renta-apps/athenaeum-toolkit";

import styles from "../DeviceDetails.module.scss";
import SimpleTable, {SimpleTableRowData} from "@/components/SimpleTable/SimpleTable";
import LogDocumentEvent from "@/models/server/LogDocumentEvent";
import { logDocumentEvent } from "@/services/ConstructionSiteService";

interface IDeviceDetailsDocumentsProps {}

const DeviceDetailsDocuments: React.FC<IDeviceDetailsDocumentsProps> = (props: IDeviceDetailsDocumentsProps) => {

    const context = useContext(DeviceDetailsContext);

    const [deviceDocuments, setDeviceDocuments] = useState<FileModel[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const downloadDocument = async (rowData: SimpleTableRowData) => {

        const model = rowData as FileModel;

        const request: LogDocumentEvent = {
            fileId: model.id,
            fileName: model.name,
            inventoryItemId: context.rentaId,
            rentalObjectNumber: context.rentaGroup,
        };

        logDocumentEvent(request);

        ch.download(model);
    }

    const tableHeaders = [
        { key: "name", label: Localizer.genericFileName }, // "Filename"
        {
            key: "type", label: Localizer.genericFileType, render: (rowData: SimpleTableRowData) => (
                rowData.type?.split("/")[1].toUpperCase()
            )
        },
        {
            key: "download", label: "", render: (rowData: SimpleTableRowData) => (
                <Button type={ButtonType.Icon} className={`${styles.downloadButton} downloadButton`} onClick={() => downloadDocument(rowData)} >
                    <Icon name={"fa-download"} />
                </Button>
            )
        },
    ];

    const loadDeviceDocuments = async (rentaId: string): Promise<void> => {
        try {
            setIsLoading(true);
            const response = await getDeviceDocumentsAsync(rentaId);
            if (response)
                setDeviceDocuments(response);
            setIsLoading(false);
        } catch (error) {
            console.error('Error loading device documents:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (context.rentaId)
            loadDeviceDocuments(context.rentaId);
    }, []);

    return (
        <div className={`${styles.deviceDetailsDocuments} ${styles.moduleWrapper}`}>
            <Text tag="h3" transform="uppercase" className={styles.moduleTitle}>
                {Localizer.deviceDetailsDocumentsTitle}
            </Text>

            <Box className={styles.moduleContainer}>
                <Box className={styles.moduleContentContainer} display="flex" flexDirection="column" data-cy={"deviceDocuments"}>
                    {deviceDocuments?.length ? (
                        <SimpleTable headers={tableHeaders} data={deviceDocuments} rowGridClassName={styles.rowGrid}/>
                    ) : (
                        <Box p={16}>
                            <Text tag="span" weight="bold">{Localizer.deviceDetailsDocumentsNoDocuments}</Text>
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default DeviceDetailsDocuments;