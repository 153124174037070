import {createContext} from "react";
import {DeviceDetailedModel} from "@/models/server/DeviceDetailedModel";
import { IDeletedAlarm, INewAlarm } from "../FleetMonitoring/FleetMonitoringContainer";

export type DeviceDetailsContextType = {
    serialNumber: string | null;
    rentaId: string | null;
    rentaGroup: string | null;
    rentaGroupId: string | null;
    userRoleConstructionSiteId: string | null;
    userRoleContractId: string | null;
    userRoleIsAdmin: boolean | null;
    deviceDetails?: DeviceDetailedModel | null;
    removeDeletedAlarms: (deletedAlarms: IDeletedAlarm[]) => void;
    saveNewAlarms: (newAlarms: INewAlarm[]) => void;
}

const DeviceDetailsContext = createContext<DeviceDetailsContextType>({
    serialNumber: null,
    rentaId: null,
    rentaGroup: null,
    rentaGroupId: null,
    userRoleConstructionSiteId: null,
    userRoleContractId: null,
    userRoleIsAdmin: null,
    deviceDetails: null
} as DeviceDetailsContextType);

export default DeviceDetailsContext;