import { pageData } from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";

describe('Desktop - Logged in company user - device details page tests', () => {

    const responseContainer = {
        "value": null,
        "context": null,
        "newVersionAvailable": false,
        "redirect": null,
        "error": null,
        "alert": null,
        "unauthorized": false,
        "isResponseContainer": true
    };

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });
    });

    it('should open device details page and render items correctly when data is not available', () => {

        cy.intercept('POST', '/api/Locations/GetDeviceFuelLevel', { statusCode: 200, body: responseContainer });
        cy.intercept('POST', '/api/Fleet/GetDeviceExtendedDetails', { statusCode: 200, body: responseContainer });
        cy.intercept('POST', '/api/Product/GetMachineDocuments', { statusCode: 200, body: responseContainer });
        cy.intercept('POST', '/api/Locations/GetDeviceUsage', { statusCode: 200, body: responseContainer });

        cy.visit('/device-details?serialNumber=39-16119-AEMP-D25B661472&rentaId=93289&rentaGroup=17850');

        // Test DeviceDetailsLocation
        cy.get('h3').contains(Localizer.deviceDetailsLocationTitle);

        cy.get('span').contains(Localizer.deviceDetailsLocationNoLocation);

        // Test DeviceDetailsAlerts
        cy.get('h3').contains(Localizer.deviceDetailsAlertsTitle);

        cy.get('span').contains(Localizer.deviceDetailsAlertsNoAlerts);

        // Test DeviceDetailsUsage
        cy.get('h3').contains(Localizer.deviceDetailsUsageHistoryTitle);

        cy.get('span').contains(Localizer.deviceDetailsUsageHistoryNoUsageHistory);

        // Test DeviceDetailsDocuments
        cy.get('h3').contains(Localizer.deviceDetailsDocumentsTitle);

        cy.get('span').contains(Localizer.deviceDetailsDocumentsNoDocuments);

    });

    it('should open device details page and render items correctly when only extended details are available', () => {

        cy.intercept('POST', '/api/Locations/GetDeviceFuelLevel', { statusCode: 200, body: responseContainer });
        cy.intercept('POST', '/api/Fleet/GetDeviceExtendedDetails', { fixture: 'getDeviceExtendedDetails.json' });
        cy.intercept('POST', '/api/Product/GetMachineDocuments', { statusCode: 200, body: responseContainer });
        cy.intercept('POST', '/api/Locations/GetDeviceUsage', { statusCode: 200, body: responseContainer });

        cy.visit('/device-details?serialNumber=39-16119-AEMP-D25B661472&rentaId=93289&rentaGroup=17850');

        // Test DeviceDetailsHeader
        cy.get('i.fa-gas-pump').should('exist');

        cy.get('div[data-cy="fluidLevel"] span').contains('50%');

    });

    it('should open device details page and render items correctly when data is available', () => {

        interceptCommonRequests();

        cy.visit('/device-details?serialNumber=39-16119-AEMP-D25B661472&rentaId=93289&rentaGroup=17850&rentaGroupId=17850');

        // Test DeviceDetailsHeader
        cy.get('div[data-cy="deviceDetails"] h3').contains('2-VALSSIJYRÄ 2-5 T');

        cy.get('div[data-cy="deviceDetails"] h2').contains('93289 Volvo DD25B');

        cy.get('label').contains(Localizer.genericRentaId);

        cy.get('span[data-cy="rentaId"]').contains('93289');

        cy.get('label').contains(Localizer.genericConstructionSite);

        cy.get('span[data-cy="constructionSiteName"]').contains('ÅKERONTIE 8');

        cy.get('label').contains(Localizer.genericCompany);

        cy.get('span[data-cy="customerName"]').contains('TMI JUKKA WILSKA');

        cy.get('span').contains(Localizer.fleetMonitoringPageGridFluid);

        cy.get('span').contains(Localizer.fleetMonitoringPageGridBattery);

        cy.get('span').contains(Localizer.fleetMonitoringPageGridAlerts);

        cy.get('span').contains(Localizer.fleetMonitoringPageGridIdle);

        cy.get('i.fa-droplet').should('exist');

        cy.get('div[data-cy="fillPercentage"] span').contains('86%');

        cy.get('i.fa-battery-half').should('exist');

        cy.get('i.fa-bell').should('exist');

        cy.get('div[data-cy="alertsCount"] span').contains('1');

        cy.get('i.fa-location-dot').should('exist');

        cy.get('i.fa-clock').should('exist');

        cy.get('div[data-cy="idleDays"] span').contains('2d');

        // Test DeviceDetailsLocation
        cy.get('h3').contains(Localizer.deviceDetailsLocationTitle);

        cy.get('div[data-cy="deviceLocation"]')
            .should('exist');

        // Test DeviceDetailsAlerts
        cy.get('h3').contains(Localizer.deviceDetailsAlertsTitle);

        cy.get('#alertsSettingsButton')
            .should('exist');

        cy.get('div[data-cy="configuredAlerts"]')
            .children()
            .should('have.length', 1)
            .children('div')
            .should('have.length', 2)
            .eq(0) // Select the first child div
            .should('contain.text', Localizer.genericDescription)
            .should('contain.text', Localizer.genericTime)
            .next() // Move to the next sibling div
            .should('contain.text', '93289-alarm-moved-during-off-hours')
            .should('contain.text', '19.08.24')

        // Test DeviceDetailsUsage
        cy.get('h3').contains(Localizer.deviceDetailsUsageHistoryTitle);

        cy.get('div[data-cy="deviceUsage"]')
            .should('exist');

        // Test DeviceDetailsDocuments
        cy.get('h3').contains(Localizer.deviceDetailsDocumentsTitle);

        cy.waitSuccess('@getMachineDocuments');

        cy.wait(200);

        cy.get('div[data-cy="deviceDocuments"]')
            .children()
            .should('have.length', 1)
            .children('div')
            .should('have.length', 3)
            .eq(0)
            .should('contain.text', Localizer.genericFileName)
            .should('contain.text', Localizer.genericFileType)
            .next()
            .should('contain.text', 'Manitou 180 ATJ.pdf')
            .should('contain.text', 'PDF')
            .find('button')
            .should('exist')
            .parents('div') // Return to the parent div
            .next()
            .should('contain.text', '20-185 CE samsvar.pdf')
            .should('contain.text', 'PDF')
            .find('button')
            .should('exist');
    });

    it('should open construction site page when click on the construction site name', () => {

        interceptCommonRequests();

        cy.visit('/device-details?serialNumber=39-16119-AEMP-D25B661472&rentaId=93289&rentaGroup=17850');

        cy.get('span[data-cy="constructionSiteName"]').contains('ÅKERONTIE 8').click();

        cy.url().should('include', `${Localizer.pageRoutesConstructionSiteDetails}?id=81099f31-614e-4d56-9031-416bf4a9212e`);

    });

    it('should open company page when click on the company name', () => {

        interceptCommonRequests();

        cy.visit('/device-details?serialNumber=39-16119-AEMP-D25B661472&rentaId=93289&rentaGroup=17850');

        cy.get('span[data-cy="customerName"]').contains('TMI JUKKA WILSKA').click()

        cy.url().should('include', `${Localizer.pageRoutesContractDetails}?id=31eaa6c0-42fa-40c2-a891-bcd16c367409`);
    });

    it('should open device details page and edit alert settings successfully', () => {

        interceptCommonRequests();

        cy.intercept('POST', '/api/Alarm/CreateDeviceFuelLevelLessThanAlarm', { fixture: 'createDeviceFuelLevelLessThanAlarm.json' }).as('createAlarmRequest');
        cy.intercept('POST', '/api/Alarm/DeleteAlarm', { fixture: 'deleteAlarm.json' }).as('deleteAlarmRequest');

        cy.visit('/device-details?serialNumber=39-16119-AEMP-D25B661472&rentaId=93289&rentaGroup=17850');

        // Open modal
        cy.get('#alertsSettingsButton')
            .should('exist')
            .trigger('click');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('be.visible');

        // Fuel is unchecked, so check it.
        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .should('exist')
            .verifyDataValue('false');

        pageData().fleetMonitoring.modals.subscribeToAlerts.fuelCheckbox()
            .click();

        pageData().fleetMonitoring.modals.subscribeToAlerts.batteryCheckbox()
            .should('exist')
            .verifyDataValue('false');

        // Theft is checked, so uncheck it.
        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .should('exist')
            .verifyDataValue('true');

        pageData().fleetMonitoring.modals.subscribeToAlerts.theftCheckbox()
            .click();

        pageData().fleetMonitoring.modals.subscribeToAlerts.idleCheckbox()
            .should('exist')
            .verifyDataValue('false');

        // Notification checkboxes
        pageData().fleetMonitoring.modals.subscribeToAlerts.smsCheckbox()
            .should('exist')
            .verifyDataValue('true');

        pageData().fleetMonitoring.modals.subscribeToAlerts.emailCheckbox()
            .should('exist')
            .verifyDataValue('false');

        // Save changes and close the modal
        pageData().fleetMonitoring.modals.subscribeToAlerts.saveButton()
            .trigger('click');

        // Wait for the intercepted requests to be made
        cy.waitSuccess('@createAlarmRequest');
        cy.waitSuccess('@deleteAlarmRequest');

        pageData().fleetMonitoring.modals.subscribeToAlerts.modal()
            .should('not.exist');

        // Verify the list of alarms has been updated
        cy.get('div[data-cy="configuredAlerts"]')
            .children()
            .should('have.length', 1)
            .children('div')
            .should('have.length', 2)
            .eq(0) // Select the first child div
            .should('contain.text', Localizer.genericDescription)
            .should('contain.text', Localizer.genericTime)
            .next() // Move to the next sibling div
            .should('contain.text', '90193-alarm-fuel-low')
            .should('not.contain.text', '19.08.24')

    });

    it('should download a file successfully', () => {

        interceptCommonRequests();

        cy.intercept('POST', '/api/ConstructionSites/LogDocumentEvent', { statusCode: 200, body: responseContainer }).as('LogDocumentEvent');

        cy.visit('/device-details?serialNumber=39-16119-AEMP-D25B661472&rentaId=93289&rentaGroup=17850');

        cy.waitSuccess('@getMachineDocuments');

        cy.wait(200);

        cy.get('.downloadButton').first().click();

        cy.wait('@LogDocumentEvent').then(({ request }) => {
            expect(request.body).to.have.property('fileId', '1');
            expect(request.body).to.have.property('fileName', 'Manitou 180 ATJ.pdf');
            expect(request.body).to.have.property('inventoryItemId', '93289');
            expect(request.body).to.have.property('rentalObjectNumber', '17850');
        });
    });

    function interceptCommonRequests() {
        cy.intercept('POST', '/api/Locations/GetDeviceFuelLevel', { fixture: 'getDeviceFuelLevel.json' });
        cy.intercept('POST', '/api/Fleet/GetDeviceExtendedDetails', { fixture: 'getDeviceExtendedDetails.json' });
        cy.intercept('POST', '/api/Product/GetMachineDocuments', { fixture: 'machineDocumentsData.json' }).as('getMachineDocuments');
        cy.intercept('POST', '/api/Locations/GetDeviceUsage', { fixture: 'getDeviceUsage.json' });
    }
});
