import React, {useEffect, useMemo, useState} from 'react';
import {Button, ButtonSize, ButtonType, Checkbox, Icon, IconSize} from '@renta-apps/renta-react-components';
import EasyPlusFeatureButton from "@/components/EasyPlus/EasyPlusFeatureButton/EasyPlusFeatureButton";
import InlineTooltip from "@/components/InlineTooltip/InlineTooltip";
import MeasurementValue from "@/components/MeasurementValue/MeasurementValue";
import {capitalizeWords} from '@/helpers/StringHelper';
import Localizer from "@/localization/Localizer";
import {DeviceModel} from "@/models/server/DeviceModel";
import {DeviceDetails} from '@/models/server/DeviceDetails';
import styles from './DeviceGrid.module.scss';
import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

interface IDeviceGridProps {
    devices: () => DeviceModel[];
    deviceDetails: (assetId: string, rentaGroupId: string, rentaId: string) => Promise<DeviceDetails>;
    onGearButtonClick: (deviceId: string) => void;
    onDebugClick?: (assetId: string, rentaGroupId: string, rentaId: string) => Promise<string>;
    onConstructionSiteLinkClick: (constructionSiteId: string) => void;
    onCompanyLinkClick: (contractId: string) => void;
    onRowCheckboxClick: (selectedDevicesIds: string[]) => void;
}

const DeviceGrid: React.FC<IDeviceGridProps> = ({
                                                    devices,
                                                    deviceDetails,
                                                    onConstructionSiteLinkClick,
                                                    onDebugClick,
                                                    onCompanyLinkClick,
                                                    onGearButtonClick,
                                                    onRowCheckboxClick,
                                                }) => {

    const [data, setData] = useState<DeviceModel[]>(devices);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
    const [deviceDetailsMap, setDeviceDetailsMap] = useState<{ [key: string]: DeviceDetails | null }>({});
    
    const isDeviceDetailsEnabled = useMemo(() => UnleashHelper.isEnabled(RentaEasyConstants.featureFlagDeviceDetails), []);

    useEffect(() => {
        setData(devices);
    }, [devices]);

    const getActivityHours = (hours: number | null | undefined): string | null => {
        if (hours === null || hours === undefined) {
            return null;
        }

        return Localizer.get(Localizer.fleetMonitoringPageGridActivityHoursValue, Math.round(hours));
    };

    const getName = (name: string | undefined): string => {
        if (name) {
            return capitalizeWords(name);
        }

        return '-';
    };

    const getRentalStartDate = (date: Date | null | undefined): string | null => {
        if (date === null || date === undefined) {
            return null;
        }

        return date.toLocaleDateString();
    };

    const handleDeviceNameClick = async (device: DeviceModel) => {
        const route = isDeviceDetailsEnabled ? 
            PageDefinitions.deviceDetailsPage.route({
                params: {
                    serialNumber: device.trackUnitSerialNumber,
                    rentaId: device.rentaId,
                    rentaGroup: device.rentaGroup,
                    rentaGroupId: device.rentaGroupId,
                }
            }) : 
            PageDefinitions.productLocation.route({
                params: {
                    trackingSerialNumber: device.trackUnitSerialNumber,
                    rentaId: device.rentaId,
                    rentalObjectId: device.rentaGroupId,
                }
            });

        await PageRouteProvider.redirectAsync(route);
    }

    const handleAccordionButtonClick = async (assetId: string, rentaGroupId: string, rentaId: string) => {
        setExpandedItems(prevExpandedItems => {
            const newExpandedItems = new Set(prevExpandedItems);
            if (newExpandedItems.has(rentaId)) {
                newExpandedItems.delete(rentaId);
            }
            else {
                newExpandedItems.add(rentaId);
            }
            return newExpandedItems;
        });

        if (!deviceDetailsMap[rentaId]) {
            try {
                const details = await deviceDetails(assetId, rentaGroupId, rentaId);
                setDeviceDetailsMap(prevDetails => ({...prevDetails, [rentaId]: details}));
            }
            catch (error) {
                console.error('Error loading device details:', error);
                setDeviceDetailsMap(prevDetails => ({...prevDetails, [rentaId]: null}));
            }
        }
    };

    const handleConstructionSiteLinkClick = (constructionSiteId: string | null | undefined) => {
        if (constructionSiteId !== null && constructionSiteId !== undefined) {
            onConstructionSiteLinkClick(constructionSiteId);
        }
    };

    const handleCompanyLinkClick = (customerId: string | null | undefined) => {
        if (customerId !== null && customerId !== undefined) {
            onCompanyLinkClick(customerId);
        }
    };

    const handleDebugClick = async (assetId: string, rentaGroupId: string, rentaId: string) => {
        if (onDebugClick) {
            const data: string = await onDebugClick(assetId, rentaGroupId, rentaId);

            console.info(JSON.parse(data));
            window.alert("Check console log for data");
        }
    };

    const handleHeaderAccordionClick = async () => {
        const allExpanded = expandedItems.size === data.length;

        if (allExpanded) {
            // Collapse all items
            setExpandedItems(new Set());
        }
        else {
            // Expand all items
            const newExpandedItems = new Set(data.map(item => item.rentaId));
            setExpandedItems(newExpandedItems);

            // Fetch device details for all items that are not already in the map
            const detailsFetchPromises = data.map(async item => {
                const {assetId, rentaGroupId, rentaId} = item;
                if (!deviceDetailsMap[rentaId]) {
                    try {
                        const details = await deviceDetails(assetId, rentaGroupId, rentaId);
                        setDeviceDetailsMap(prevDetails => ({...prevDetails, [rentaId]: details}));
                    }
                    catch (error) {
                        console.error('Error loading device details:', error);
                        setDeviceDetailsMap(prevDetails => ({...prevDetails, [rentaId]: null}));
                    }
                }
            });

            await Promise.all(detailsFetchPromises);
        }
    };

    const handleHeaderCheckboxClick = () => {
        // If all checkboxes are selected, deselect them. Otherwise select all checkboxes.
        const selectedIds: string[] = selectedItems.length === data.length ? [] : data.map(row => row.rentaId);

        // Update parent component what items are selected.
        onRowCheckboxClick(selectedIds);

        setSelectedItems(selectedIds);
    };

    const handleRowCheckboxClick = (itemId: string) => {
        const selectedIds: string[] = selectedItems.includes(itemId)
            ? selectedItems.filter(id => id !== itemId)
            : [...selectedItems, itemId];

        onRowCheckboxClick(selectedIds);

        setSelectedItems(selectedIds);
    };

    const hasExtraIcon = (item: DeviceModel): boolean => {
        return !item.isRented || item.isReturnRequested;
    };

    return (
        <>
            {/* Display information about no data */}
            {data.length === 0 && <div>
                {Localizer.fleetMonitoringPageGridEmpty}
            </div>
            }

            {data.length > 0 &&
                <div className={styles.dataTable}>
                    {/* Header Row */}
                    <div className={`${styles.gridRow} ${styles.dataRow} ${styles.header}`}>
                        <div className={styles.name}>
                            <div className={styles.checkboxCell}>
                                <Checkbox
                                    checked={selectedItems.length === data.length}
                                    onChange={handleHeaderCheckboxClick}
                                />
                            </div>
                            <div>{Localizer.fleetMonitoringPageGridDeviceName}</div>
                        </div>
                        <div className={styles.rentaId}>{Localizer.fleetMonitoringPageGridId}</div>
                        <div className={styles.alerts}>{Localizer.fleetMonitoringPageGridAlerts}</div>
                        <div className={styles.idle}>{Localizer.fleetMonitoringPageGridIdle}</div>
                        <div className={styles.battery}>{Localizer.fleetMonitoringPageGridBattery}</div>
                        <div className={styles.fluid}>{Localizer.fleetMonitoringPageGridFluid}</div>
                        <div className={`${styles.gridItem} ${styles.actions}`}>
                            <div className={styles.accordionButton}
                                 onClick={() => handleHeaderAccordionClick()}
                            >
                                <i className={`fas fa-angle-down ${(expandedItems.size === data.length) ? styles.rotated : ''}`}></i>
                            </div>
                        </div>
                    </div>

                    {/* Data Rows */}
                    {data.map((item, index) => (
                        <div key={item.rentaId} className={`${styles.dataRow} ${index % 2 === 0 ? '' : styles.odd}`}>
                            <div className={styles.gridRow}>
                                {/* Identity data displayed on all screen sizes */}
                                <div className={styles.name}>
                                    <div className={styles.checkboxCell}>
                                        <Checkbox
                                            checked={selectedItems.includes(item.rentaId)}
                                            onChange={() => handleRowCheckboxClick(item.rentaId)}
                                        />
                                        {!item.isRented && <InlineTooltip
                                            text={Localizer.fleetMonitoringPageGridTooltipAtDepot}
                                            icon={<Icon name="fas fa-house" size={IconSize.Small}/>}
                                            className={styles.tooltipIcon}
                                            noOpacity={true}
                                            onClick={(event) => event.stopPropagation()}
                                        />}

                                        {item.isReturnRequested && <InlineTooltip
                                            text={Localizer.fleetMonitoringPageGridTooltipReturnRequested}
                                            icon={<Icon name="fas fa-truck" size={IconSize.Small}/>}
                                            className={styles.tooltipIcon}
                                            noOpacity={true}
                                            onClick={(event) => event.stopPropagation()}
                                        />}
                                    </div>

                                    <div className={`${styles.gridChild} ${styles.font500Regular} ${hasExtraIcon(item) ? styles.paddingLeft19 : ''}`}>
                                        <span className={`${styles.link} ${styles.ellipsisText}`} onClick={() => handleDeviceNameClick(item)}>
                                            {capitalizeWords(item.name)}
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.rentaId}>{item.rentaId}</div>
                                <div className={`${styles.alerts} ${styles.valueRow}`}>
                                    <div className={styles.label}>{Localizer.fleetMonitoringPageGridAlerts}:</div>
                                    <div className={styles.value}><MeasurementValue type="alerts" value={item.alertsCount}/></div>
                                </div>
                                <div className={`${styles.idle} ${styles.valueRow}`}>
                                    <div className={styles.label}>{Localizer.fleetMonitoringPageGridIdle}:</div>
                                    <div className={styles.value}><MeasurementValue type="idle" value={item.idleDays}/></div>
                                </div>
                                <div className={`${styles.battery} ${styles.valueRow}`}>
                                    <div className={styles.label}>{Localizer.fleetMonitoringPageGridBattery}:</div>
                                    <div className={styles.value}><MeasurementValue type="battery" value={item.batteryLevel}/></div>
                                </div>
                                <div className={`${styles.fluid} ${styles.valueRow}`}>
                                    <div className={styles.label}>{Localizer.fleetMonitoringPageGridFluid}:</div>
                                    <div className={styles.value}><MeasurementValue type="fluid" value={item.fluidLevel}/></div>
                                </div>
                                <div className={styles.actions}>
                                    <div className={styles.accordionButton}
                                         onClick={() => handleAccordionButtonClick(item.assetId, item.rentaGroupId, item.rentaId)}
                                    >
                                        <i className={`fas fa-angle-down ${expandedItems.has(item.rentaId) ? styles.rotated : ''}`}></i>
                                    </div>
                                </div>
                                <div className={styles.alertsButton}>
                                    <EasyPlusFeatureButton className={`${styles.actionsButton} gearButton`}
                                                           size={ButtonSize.Small}
                                                           type={ButtonType.Icon}
                                                           onClick={() => onGearButtonClick(item.rentaId)}>
                                        <Icon name="gear"/>
                                    </EasyPlusFeatureButton>
                                </div>
                            </div>
                            <div className={`${styles.gridRow} ${styles.expandableGridRow} ${expandedItems.has(item.rentaId) ? styles.expandedRow : ''}`}>
                                {deviceDetailsMap[item.rentaId] ? (
                                    <>
                                        <div className={`${styles.constructionSite} ${styles.expandedGridItem}`}>
                                            <span className={`${styles.font500Medium}`}>{Localizer.genericConstructionSite}</span>
                                            <span data-cy={"constructionSiteLink"}
                                                  className={`${styles.ellipsisText} ${deviceDetailsMap[item.rentaId]?.constructionSiteId ? styles.link : ''}`}
                                                  onClick={() => handleConstructionSiteLinkClick(deviceDetailsMap[item.rentaId]?.constructionSiteId)}
                                            >
                                                {getName(deviceDetailsMap[item.rentaId]?.constructionSiteName)}
                                            </span>
                                        </div>
                                        <div className={`${styles.company} ${styles.expandedGridItem}`}>
                                            <span className={`${styles.font500Medium}`}>{Localizer.genericCompany}</span>
                                            <span data-cy={"customerLink"}
                                                  className={`${styles.ellipsisText} ${deviceDetailsMap[item.rentaId]?.customerId ? styles.link : ''}`}
                                                  onClick={() => handleCompanyLinkClick(deviceDetailsMap[item.rentaId]?.customerId)}
                                            >
                                                {getName(deviceDetailsMap[item.rentaId]?.customerName)}
                                            </span>
                                        </div>
                                        <div className={`${styles.rentalStart} ${styles.expandedGridItem}`}>
                                            <span className={`${styles.font500Medium}`}>{Localizer.fleetMonitoringPageGridRentalStartDate}</span>
                                            <span>{getRentalStartDate(deviceDetailsMap[item.rentaId]?.rentalStartDate) ?? '-'}</span>
                                        </div>
                                        <div className={`${styles.activityHours} ${styles.expandedGridItem}`}>
                                            <span className={`${styles.font500Medium}`}>{Localizer.fleetMonitoringPageGridActivityHours}</span>
                                            <span>{getActivityHours(deviceDetailsMap[item.rentaId]?.activityHours) ?? '-'}</span>
                                        </div>
                                        <div className={styles.alertsButton}>
                                            <EasyPlusFeatureButton className={`${styles.actionsButton} gearButton`}
                                                                   size={ButtonSize.Small}
                                                                   type={ButtonType.Icon}
                                                                   onClick={() => onGearButtonClick(item.rentaId)}>
                                                <Icon name="gear"/>
                                            </EasyPlusFeatureButton>
                                        </div>
                                        {
                                            onDebugClick && (
                                                <div className={styles.debugButton}>
                                                    <Button className={`${styles.actionsButton} chartButton`}
                                                            size={ButtonSize.Small}
                                                            type={ButtonType.Icon}
                                                            onClick={() => handleDebugClick(item.assetId, item.rentaGroupId, item.rentaId)}>
                                                        <Icon name="fa-bug"/>
                                                    </Button>
                                                </div>
                                            )
                                        }
                                    </>
                                ) : (
                                    <div>{Localizer.componentListLoading}</div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            }
        </>
    );
};

export default DeviceGrid;