import React from "react";
import {BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import Localizer from "@/localization/Localizer";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import FleetMonitoringContainer, {FleetMonitoringFiltersAndPagination} from "./FleetMonitoringContainer";
import PageDefinitions from "../../providers/PageDefinitions";

export interface IFleetMonitoringParams extends BasePageParameters {
    sortBy?: string;
    sortOrder?: string;
    constructionSite?: string;
    constructionSiteId?: string;
    contractId?: string;
    pageNumber?: number;
    pageSize?: number;
    deviceName?: string;
    company?: string;
    companyId?: string;
    productGroup?: string;
    productGroupId?: string;
}

interface IFleetMonitoringState {
}

export default class FleetMonitoring extends AuthorizedPage<IFleetMonitoringParams, IFleetMonitoringState> {
    protected get title(): string {
        return Localizer.fleetMonitoringPageTitle;
    }

    private async handleOnConstructionSiteLinkClickAsync(constructionSiteId: string) {
        await PageRouteProvider.redirectAsync(PageDefinitions.constructionSite.route({
            params: {
                id: constructionSiteId
            }
        }));
    }

    private async handleOnCompanyLinkClickAsync(contractId: string) {
        await PageRouteProvider.redirectAsync(PageDefinitions.contractDetails.route({
            params: {
                id: contractId
            }
        }));
    }

    private async handleOnFilterParamsChangeAsync(params: FleetMonitoringFiltersAndPagination) {
        const {
            constructionSite,
            constructionSiteId,
            deviceName,
            company,
            companyId,
            productGroup,
            productGroupId,
            pageNumber,
            pageSize,
            sortBy,
            sortOrder
        } = params;

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(
            PageDefinitions.fleetMonitoringPage.route({
                params: {
                    ...(constructionSite !== undefined && {constructionSite}),
                    ...(constructionSiteId !== undefined && {constructionSiteId}),
                    ...(deviceName !== undefined && {deviceName}),
                    ...(company !== undefined && {company}),
                    ...(companyId !== undefined && {companyId}),
                    ...(productGroup !== undefined && {productGroup}),
                    ...(productGroupId !== undefined && {productGroupId}),
                    pageNumber,
                    pageSize,
                    sortBy,
                    sortOrder
                }
            })
        );
    }

    public render(): React.ReactNode {
        let filters: FleetMonitoringFiltersAndPagination | undefined = undefined;
        if (!!this.typedParameters && Object.values(this.typedParameters).some(x => !!x)) {
            filters = new FleetMonitoringFiltersAndPagination(
                this.typedParameters.constructionSite,
                this.typedParameters.constructionSiteId,
                this.typedParameters.deviceName,
                this.typedParameters.productGroup,
                this.typedParameters.productGroupId,
                this.typedParameters.company,
                this.typedParameters.companyId,
                this.typedParameters.pageNumber,
                this.typedParameters.pageSize,
                this.typedParameters.sortBy,
                this.typedParameters.sortOrder
            );
        }
        return (
            <FleetMonitoringContainer
                urlParams={filters}
                userRoleConstructionSiteId={this.userContext.selectedConstructionSiteId}
                userRoleContractId={this.userContext.selectedContractId}
                userRoleIsAdmin={this.isAdmin}
                onConstructionSiteLinkClick={async (constructionSiteId: string) => await this.handleOnConstructionSiteLinkClickAsync(constructionSiteId)}
                onCompanyLinkClick={async (contractId: string) => await this.handleOnCompanyLinkClickAsync(contractId)}
                onFilterParamsChange={(params) => this.handleOnFilterParamsChangeAsync(params)}
            />
        );
    }
}